import { Component, OnInit } from '@angular/core';
import {ModalController } from '@ionic/angular';

import * as moment from 'moment';
import { format, parseISO } from 'date-fns';
@Component({
  selector: 'app-date-filter',
  templateUrl: './date-filter.component.html',
  styleUrls: ['./date-filter.component.scss'],
})



export class DateFilterComponent implements OnInit {

  inputValue:number;
  canSet:boolean=false;
  startDate:string='2022-03-21T00:32:00+03:00';;
  endDate:string;
  sstring:string;
  estring:string;
  sdate:Date;
  edate:Date;
  constructor(
    public modalController:ModalController
    ) {
      this.startDate =format(new Date(),'yyyy-MM-dd')+'T00:00:00+00';
      this.endDate =format(new Date(),'yyyy-MM-dd')+'T00:00:00+00';
      this.sstring = this.startDate.substring(0,10);
      this.estring = this.endDate.substring(0,10);
   }
   ngOnInit() {

    
    
  }
   onDateChanged(input)
   {
     this.validate();
      
   }
   dateChanged(value,segment)
   {
     
    if(segment =="start")
    {
      this.startDate = value;
    }
    else
    {
      this.endDate = value;
    }
     this.validate();

      
   }
   onClear()
   {
     this.modalController.dismiss(null,'clear');
   }
   async onCancel()
   {
     
    await this.modalController.dismiss();
   }
   onSetClick()
   {
     
     this.modalController.dismiss({startDate:moment(this.startDate.substring(0,10),"yyyy-MM-dd").toDate(),endDate:moment(this.startDate.substring(0,10),"yyyy-MM-dd").toDate()},'done');
   }

  validate()
  {
     this.sstring = this.startDate.substring(0,10);
     this.estring = this.endDate.substring(0,10);
     let s :Date = new Date(this.sstring+ ' 00:00:00')
     let e :Date = new Date(this.estring+ ' 00:00:00')
     if(e>s)
    {
      this.canSet = true;
    }
    else
    {
      this.canSet = false;
    }
    
  }

}
