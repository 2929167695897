import { createAction, props } from "@ngrx/store";
import { Account } from "src/app/models/Account/Account";


export const setCurrentAccountAction = createAction("[Account] Set",props<{account:Account}>());
export const saveCurrentAccountAction = createAction("[Account] Save",props<{account:Account}>());
export const saveCurrentAccountSuccessAction= createAction("[Account] Success",props<{account:Account}>());
export const saveCurrentAccountFailAction = createAction("[Account] Success",props<{error:any}>());
export const fetchCurrentAccountAction = createAction("[Account] Fetch",props<{code:string}>());
export const fetchCurrentAccountSuccessAction = createAction("[Account] Fetch Fail",props<{account:Account}>());
export const fetchCurrentAccountFailAction = createAction("[Account] Fetch Success",props<{error:any}>());
