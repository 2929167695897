import { Component, OnInit } from '@angular/core';
import { ModalController, ToastController } from '@ionic/angular';
import { ExtremeService } from 'src/app/services/extreme.service';
import { SettingService } from 'src/app/services/setting.service';


@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
})
export class SettingsComponent implements OnInit {


  
  serverUrl:string;
  locationAlias:string;
  defaultPayment:string;
  timeout:string;
  barcodeTime:string;
  constructor(
    private extreme:ExtremeService,
    private toaster:ToastController,
    private setting:SettingService,
    private modalController:ModalController
    
    ) {
    
   }
   
  ngOnInit() {
    this.setting.get("serverUrl").then(result=>
      {
         this.serverUrl = result;
         this.setting.set("serverUrl",this.serverUrl);
         this.extreme.setBaseUrl(this.serverUrl);
      });
      this.setting.get("locationAlias").then(result=>
        {
           this.locationAlias = result;
        });
        this.setting.get("defaultPayment").then(result=>
          {
             this.defaultPayment = result;
          });      
          this.setting.getTimeout().then(result=>
            {
              this.timeout = result;
            })  
            this.setting.getbarcodeTimeout().then(result=>
              {
                this.barcodeTime = result;
              })                        
    
  }
  onTestClick()
  {
    this.extreme.testServer(this.serverUrl).subscribe(result=>{
        this.toastMessage(result.data,false);
    },error=>
    {
      console.log(error);
      
      this.toastMessage("Hata:"+error.message+"-",true);
      
    });

  }
   onSaveClick()
  {
    this.setting.set("serverUrl",this.serverUrl);
    this.setting.set("locationAlias",this.locationAlias);
    this.setting.set("defaultPayment",this.defaultPayment);
    this.setting.setTimeout(this.timeout);
    this.extreme.setBaseUrl(this.serverUrl);
    this.extreme.setLocationAlias(this.locationAlias);
    this.setting.setbarcodeTimeout(parseInt(this.barcodeTime,10));
    this.extreme.setTimeout(parseInt(this.timeout,10));

  }
  async toastMessage(message:string,isError)
  {
    const toaster = await this.toaster.create(
      {
        position:"middle",
        message:message,
        color: isError ?  "danger":"success",
        duration:2000
        
      }
    );
    toaster.present();
  }
  onCancel()
  {
    this.modalController.dismiss(null,"cancel");
  }

}
