import { Account } from "src/app/models/Account/Account";
import { AppState } from "./AppState";

export const AppInitialState :AppState=
{
    loading: {
        show : false
    },
    login:{
        error:null,
        isRecoveringPassword :false,
        isRecoveredPassword:false,
        isLogginin:false,
        isLogedIn:false
    },
    currentAccount:
    {
        account:new Account(),
        isEditing:false,
        error:null,
        isSavedSucces:false,
        isSaveFail:false,
        isTouched:false,
        isSaving : false,
        isFetching:false,
        isFetchFail:false,
        isFetchSuccess:false
        
    }
}