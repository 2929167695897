import { Address } from "../address";
import { PaymentPlan } from "../Order/paymentplan";
import { TaxOffice } from "../taxoffice";
import { Category } from "./Category";
import { PriceList } from "./PriceList";
import { Territory } from "./Territory";

export class Account{
    recId:number;
    code:string;
    name:string;
    taxNo:string;
    taxOffice:TaxOffice;
    shipmentAddress:Address;
    invoiceAddress:Address;
    priceList:PriceList;
    territory:Territory;
    category:Category;
    paymentPlan:PaymentPlan;
}