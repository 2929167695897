import { createReducer, on } from "@ngrx/store";
import { AppInitialState } from "../AppInitialState";
import { login, loginFail, loginSuccess, recoverPassword, recoverPasswordFail, recoverPasswordSuccess } from "./login.actions";
import { LoginState } from "./LoginState";

const initialState: LoginState = AppInitialState.login;
const reducer = createReducer(initialState,
    on(recoverPassword, currentState => {
        return {
            ...currentState,
            error:null,
            isRecoveredPassword:false,
            isRecoveringPassword:true
        };
        
    }),
    on(recoverPasswordSuccess, currentState => {
        return {
          ...currentState,
            error:null,
            isRecoveredPassword:true,
            isRecoveringPassword:false
        };
    }),
    on(recoverPasswordFail, (currentState,action) => {
        return {
            ...currentState,
            error:action.error,
            isRecoveredPassword:false,
            isRecoveringPassword:false
        };
    }),
    on(login, (currentState) => {
        return {
            ...currentState,
            error:null,
            isLogginin:true,
            isLogedIn:false
        };
    }),
    on(loginSuccess, (currentState) => {
        return {
            ...currentState,
            error:null,
            isLogginin:false,
            isLogedIn:true
        };
    }),
    on(loginFail, (currentState,action) => {
        return {
            ...currentState,
            error:action.error,
            isLogginin:false,
            isLogedIn:false
        };
    }),

)

export function loginReducer(state:LoginState,action)
{
    return reducer(state,action);
}