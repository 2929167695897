import { NgModule,APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA ,LOCALE_ID } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { AppStoreModule } from 'src/store/AppStoreModule';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { IonicStorageModule } from '@ionic/storage-angular';


import { environment } from 'src/environments/environment';
import { BarcodeScanner } from '@awesome-cordova-plugins/barcode-scanner/ngx';
import { IonicSelectableModule } from 'ionic-selectable';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from "@angular/forms"
import { LoadingComponent } from './components/loading/loading.component';
import { SearchAccountComponent } from './components/search-account/search-account.component';
import { SettingService } from './services/setting.service';
import { SettingsComponent } from './components/settings/settings.component';
import { SetQuantityComponent } from './components/set-quantity/set-quantity.component';
import { DateFilterComponent } from './components/date-filter/date-filter.component';
//import{BarcodeListItemComponent} from './components/barcode-list-item/barcode-list-item.component';
import { AuthService } from './services/auth/auth.service'
import { DecimalPipe } from '@angular/common';
import { registerLocaleData } from '@angular/common';
import localTr from '@angular/common/locales/tr';
import { MenuService } from './services/menu.service';

registerLocaleData(localTr);

@NgModule({
  declarations: [
    AppComponent,
    LoadingComponent,
    SearchAccountComponent,
    SettingsComponent,
    SetQuantityComponent,
    DateFilterComponent
    ],
  entryComponents: [],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    IonicSelectableModule,
    ...AppStoreModule,
    StoreDevtoolsModule.instrument({maxAge:25}),
    HttpClientModule
    ,FormsModule
    ,IonicStorageModule.forRoot()
    
    
  ],
    
  providers: [
    { provide: LOCALE_ID, useValue: 'tr-TR'},
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy}
    ,{provide : APP_INITIALIZER, useFactory : initFunction, deps: [SettingService] , multi : true}
    ,SettingService
    ,MenuService
    ,BarcodeScanner
    ,AuthService
    ,DecimalPipe
    //,BarcodeListItemComponent
  ],
  bootstrap: [AppComponent],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ]
})
export class AppModule {}
export  function initFunction(config : SettingService)
{
  return ()=> config.init();
}