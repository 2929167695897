import { barcodeListItem } from "../BarcodeListItem";
import { SerialCard } from "../Item/SerialCard";

export class ReceiptRequest
{
    receiptNo:string;
    receiptType:string;
    AccountId:number;
    WarehouseId:number;
    OutWareHouseId:number;
    ReceiptDate:Date;
    SerialCards:SerialCard[];
    BarcodeList:barcodeListItem[];

}