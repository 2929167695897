import { Component, OnInit} from '@angular/core';
import { ModalController, ToastController } from '@ionic/angular';
import { ExtremeService } from 'src/app/services/extreme.service';
import { LoadingService } from 'src/app/services/loading.service';
import { Router } from '@angular/router';
import { Account } from 'src/app/models/Account/Account';



@Component({
  selector: 'app-search-account',
  templateUrl: './search-account.component.html',
  styleUrls: ['./search-account.component.scss'],
})
export class SearchAccountComponent implements OnInit {

  searchText:string;
  load:any;
  canSearch:boolean=false;
  accounts:Account[];

  constructor(
    private extreme: ExtremeService
    ,private toaster:ToastController
    ,private modalController:ModalController
    ,private loadingService:LoadingService
    ,private route:Router
    ) { }

  ngOnInit() {}


  onSearchClick()
  {
    
    let code: string = this.searchText;
    this.accounts = undefined;
    this.loadingService.loadingPresent("Cariler Aranıyor...");
    
    this.extreme.getAccounts(this.searchText).subscribe(result=>
      {
        this.accounts = result;
        
        console.log("Result",result);
        this.loadingService.loadingDismiss();
      },error=>
      {
        this.loadingService.loadingDismiss();
        if(error.status == "404")
        {
          this.presentToast("<b>Cari Bulunamadı.</b>");
        }
        else
         {
          this.presentToast("<b>Hata:</b>" + error.statusText,true);
          console.log("hata",error);
         }
        
      })
      this.searchText = "";
  }
  onInput($event) {
    let code: string = $event.target.value;
    this.canSearch = code.length > 2 ;

  }
  onItemClick(item)
  {
    
    this.modalController.dismiss(item,"done");
    
  }
  onCancel()
  {
    this.modalController.dismiss(null,"cancel");
  }
  async presentToast(message:string,isError:boolean=false)
  {
    const toast = await this.toaster.create({
      header:isError?"Hata":"Bilgi",
      message: message,
      duration: 2000,
      position:"middle",
      animated:true,
      color: isError? "danger" : "warning"

    });
    return await toast.present();
  }
}
