import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { UserResponse } from 'src/app/models/user/UserResponse';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  activeUser:UserResponse;
  isLoggedIn:boolean=false;
  constructor(private router:Router) { }

  recoverPassword(email:string)
  {
      return null;
  }

  login(user:UserResponse)
  {
      this.activeUser = user;
      this.isLoggedIn = true;
  }
  logout()
  {
      this.activeUser = null;
      this.isLoggedIn = false;
      this.router.navigate(["login"]);
  }
}
