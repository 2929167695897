import { Injectable, isDevMode } from '@angular/core';
import { CanLoad, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanLoad {

  constructor(
    private router:Router,
    private authService:AuthService
    ) { }
  canLoad():boolean
  {
    if(isDevMode())
    {
      return true;
    }
    else
    {
    if(this.authService.isLoggedIn)
    {
      return true;
    }
    else
    {
      this.router.navigate(["login"]);
      
    }
    }
    return true;
    
  }
}
