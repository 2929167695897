import { Injectable, Type } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, tap, timeout } from 'rxjs/operators';
import { Inventory } from '../models/inventory';
import { TaxOffice } from '../models/taxoffice';
import { Account } from '../models/Account/Account';
import { PriceList } from '../models/Account/PriceList';
import { AccountRequest } from '../models/Account/AccountRequest';
import { Il } from '../models/il';
import { SettingService } from './setting.service';
import { Order } from '../models/Order/order';
import * as moment from 'moment';
import { UserLogin } from '../models/user/UserLogin';
import { UserResponse } from '../models/user/UserResponse';
import { AuthService } from './auth/auth.service';
import { concatLatestFrom } from '@ngrx/effects';
import { SerialCard } from '../models/Item/SerialCard';
import { Receipt } from '../models/Receipt/Receipt';
import { ReceiptRequest } from '../models/Receipt/ReceiptRequest';
import { WorkOrder } from '../models/WokrOrder/WorkOrder';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class ExtremeService {

  url = 'http://localhost';
  locationAlias:string="";
  timeout:number=3000;
  constructor(
    private http: HttpClient,
    private setting:SettingService,
    private authService:AuthService,
    private router:Router
    
    ) { 
    this.setting.get("serverUrl").then(result=>{this.url = result+"/api";});
    this.setting.get("locationAlias").then(result=>{this.locationAlias = result;});
    this.setting.get("TimeOut").then(result=>{this.timeout =parseInt(result,10);});

  }
  setBaseUrl(baseUrl:string)
  {
    this.url = baseUrl+"/api";
  }
  setLocationAlias(alias:string)
  {
    this.locationAlias = this.locationAlias;
  }
  setTimeout(timeout:number)
  {
    this.timeout =timeout;
  }
  getCity():Observable<any>
  {
    return this.getRequest<any>(this.url+"/account/GetCities");
  }
  getItem(code: string): Observable<any> {
    return this.getRequest<Inventory>(this.url+"/item/GetItem?Code="+code);
  }

  getSerialCard(code:string)
  {
    return this.getRequest<SerialCard>(this.url+"/item/GetSerialCard?Code="+code);
  }
  saveReceipt(receipt:Receipt)
  {
    let r = new ReceiptRequest();
    r.AccountId = receipt.account.recId;
    r.ReceiptDate = receipt.receiptDate;
    r.WarehouseId = receipt.warehouse.recId;
    r.OutWareHouseId=receipt.warehouse.recId;
    r.SerialCards = receipt.serialCards;
    r.BarcodeList = receipt.barcodeList;
    r.receiptType = receipt.receiptType;
    return this.postRequest<ReceiptRequest>(r,this.url+"/item/SaveReceipt");
  }
  getItemByAccount(code: string,accountId:string,paymentPlanId:string): Observable<any> {
    return this.getRequest<Inventory>(`${this.url}/item/GetItemByAccount?Code=${code}&AccountId=${accountId}&PaymentPlanId=${paymentPlanId}`);
  }
  getAccounts(keyword:string): Observable<any> {
    return this.getRequest<Account>(this.url+"/account/GetAccounts?keyword="+keyword);
  }
  getAccountById(id:string): Observable<AccountRequest> {
    return this.getRequest<AccountRequest>(this.url+"/account/GetAccountById?id="+id);
  }
  getTaxOffices(): Observable<any> {
    return this.getRequest<TaxOffice[]>(this.url+"/account/GetTaxOffices");
  }
  getPriceList(): Observable<any> {
    return this.getRequest(this.url+"/account/GetPriceLists");
  }
  getTerritorys(): Observable<any> {
    return this.getRequest(this.url+"/account/GetTerritorys");
  }
  getAccountCategories(): Observable<any> {
    return this.getRequest<PriceList>(this.url+"/account/GetCategories");
  }
  getOrders(): Observable<any> {
    return this.getRequest<Order[]>(this.url+"/order/GetOrders");
  }
  getOrdersByDate(startDate:Date,endDate:Date): Observable<any> {
    const start:string = "StartDate="+moment(startDate).format("DD/MM/YYYY");
    const end:string = "EndDate="+moment(endDate).format("DD/MM/YYYY");
    const adress =this.url+"/order/GetOrdersByDate?"+start+"&"+end;
    
    return this.getRequest<Order>(this.url+"/order/GetOrdersByDate?"+start+"&"+end);
  }
  getOrderById(id): Observable<Order> {
    return this.getRequest<Order>(this.url+"/order/GetOrderById?id="+id);
  }
  getWorkOrder(value):Observable<WorkOrder>
  {
    return this.getRequest<WorkOrder>(this.url+"/workorder/GetWorkOrder?Code="+value);
  }
  addWorkOrderProduction(code:string,quantity:number)
  {
    let uri:string=this.url+"/workorder/AddWorkOrderProduction?Code="+code+"&quantity="+quantity;
    return this.getRequest<any>(uri);
  }
  getProjects(): Observable<any> {
    return this.getRequest<any>(this.url+"/order/GetProjects");
  }
  getPaymentPlans(): Observable<any> {
    
    return this.getRequest<any>(this.url+"/order/GetPaymentPlans");
  }
  getWarehouses(): Observable<any> {
    return this.getRequest(this.url+"/item/GetWarehouses");
  }
  saveAccount(account:AccountRequest)
  {
    return this.postRequest<Account>(account,this.url+"/account/SaveAccount");
  }
  saveOrder(order:Order)
  {
    return this.postRequest<Order>(order,this.url+"/order/SaveOrder");
  }
  getCriticalStock()
  {
    return this.getRequest<Inventory[]>(this.url+"/item/getcriticalstock");
    //return this.getRequest<PriceList>(this.url+"/account/GetCategories");
  }

  getPdf(id:string): Observable<any>
  {
    return this.http.get<any>(this.url+"/order/GetPdf?OrderId="+id);
  }
  getPdfUrl(id:string)
  {
    return this.url+"/order/GetPdfLink?OrderId="+id;
  }
  testServer(serverUrl:string)
  {
    return this.http.get<any>(serverUrl+"/api/login/Test").pipe(timeout(3000));
  }

  login(user:UserLogin)
  {
    return this.http.post<UserResponse>(this.url+ "/login/Login",user);
  }

  printOrder(id:string,alias:string)
  {
    return this.http.get<any>(this.url+"/order/PrintOrder?OrderId="+id+"&Alias="+alias)
  }

  getRequest<Type>(address:string)
  { try
    {
    const token:string = this.authService.activeUser.token;
    //const token:string = "5";
    const hd = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    return this.http.get<Type>(address,{headers:hd}).pipe(timeout(this.timeout));
    }
    catch(ex)
    {
      this.router.navigateByUrl("/login");
    }
  }
  getRequestAnonoym<Type>(address:string)
  { 
    try
    {
    //const token:string = this.authService.activeUser.token;
    const token:string = "0";
    const hd = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    return this.http.get<Type>(address,{headers:hd}).pipe(timeout(this.timeout));
    }
    catch(ex)
    {
      this.router.navigateByUrl("/login");
    }
  }
  postRequest<Type>(value:any,address:string)
  {
    try{
    // const token:string = this.authService.activeUser.token;
    const token:string = "5";
    const hd = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    return this.http.post<Type>(address,value,{headers:hd}).pipe(timeout(this.timeout));
    }
    catch(ex)
    {
      this.router.navigateByUrl("/login");
    }
  }
}
