import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { login, loginFail, loginSuccess, recoverPassword, recoverPasswordFail, recoverPasswordSuccess } from "./login.actions";
import { catchError, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs/';
import { AuthService } from "src/app/services/auth/auth.service";
@Injectable()
export class LoginEffects {
    constructor(private actions$: Actions, private authService: AuthService) {

    }

 
 
}