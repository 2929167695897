import { EffectsModule } from "@ngrx/effects";
import { StoreModule } from "@ngrx/store";
import { accountReducer } from "./account/account.reducers";
import { loadingReducer } from "./loading/loading.reducers";
import { LoginEffects } from "./login/login.effects";
import { loginReducer } from "./login/login.reducers";
export const AppStoreModule = 
[
    StoreModule.forRoot([]),
    StoreModule.forFeature("loading",loadingReducer),
    StoreModule.forFeature("login",loginReducer),
    StoreModule.forFeature("account",accountReducer),
    EffectsModule.forRoot([]),
    EffectsModule.forFeature([
        LoginEffects
    ])
]