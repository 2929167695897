import { Component, isDevMode } from '@angular/core';

import { AuthService } from './services/auth/auth.service';
import { MenuService } from './services/menu.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
 
  isMenuEnable: boolean = false;
  appPages:any;
  constructor(
    private authService: AuthService,
    private menuService:MenuService
  ) {
     this.appPages =menuService.appPages;
    
  }
  ngOnInit() {

    
  }

  isLogged() {
    return true;
  }
  onLogoutClick() {
    this.authService.logout();
  }


}
