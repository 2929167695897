import { createReducer, on } from "@ngrx/store";
import { AppInitialState } from "../AppInitialState";
import { AccountState } from "./account.state";
//import { login, loginFail, loginSuccess, recoverPassword, recoverPasswordFail, recoverPasswordSuccess } from "./login.actions";
import{setCurrentAccountAction,saveCurrentAccountAction,saveCurrentAccountSuccessAction,saveCurrentAccountFailAction, fetchCurrentAccountAction, fetchCurrentAccountSuccessAction, fetchCurrentAccountFailAction} from "./account.actions";

const initialState: AccountState = AppInitialState.currentAccount;
const reducer = createReducer(initialState,
    on(saveCurrentAccountAction, (currentState,action) => {
        return {
            ...currentState,
            error:null,
            account:action.account,
            isSaving:true
            
        };
        
    }),
    on(saveCurrentAccountSuccessAction, (currentState,action) => {
        return {
          ...currentState,
            error:null,
            account:action.account,
            isSaving:false,
            isSavedSucces:true,
            isSaveFail:false
        };
    }),
    on(saveCurrentAccountFailAction, (currentState,action) => {
        return {
            ...currentState,
            error:action.error,
            isSaving:false,
            isSavedSucces:false,
            isSaveFail:true
               };
    }),
    on(fetchCurrentAccountAction, (currentState,action) => {
        return {
            ...currentState,
            error:null,
            isSaving:false,
            isSavedSucces:false,
            isSaveFail:false,
            isFetching:true,
            account:null,
            isFetchSuccess:false,
            isFetchFail:false
               };
    }),
    on(fetchCurrentAccountSuccessAction, (currentState,action) => {
        return {
            ...currentState,
            error:null,
            isSaving:false,
            isSavedSucces:false,
            isSaveFail:false,
            isFetching:false,
            account:action.account,
            isFetchSuccess:true,
            isFetchFail:false
               };
    }),
    on(fetchCurrentAccountFailAction, (currentState,action) => {
        return {
            ...currentState,
            error:action.error,
            isSaving:false,
            isSavedSucces:false,
            isSaveFail:false,
            isFetching:false,
            account:null,
            isFetchSuccess:false,
            isFetchFail:true
               };
    })

)

export function accountReducer(state:AccountState,action)
{
    return reducer(state,action);
}