import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MenuService {

  public appPages = [
  {
    title:"Cari",
    url:"/account-list",
    icon:"add"
    
  }
  ,
    {
  title:"Üretim Yönetimi",
  url:"/receipt-menu/1",
  icon:"add",
  children:
  [
    {
      title:"Laboratuar Reçeleri",
      url:"/#",
      icon:"list"
    },
    {
      title:"İşletme Reçeleri",
      url:"/#",
      icon:"list"
    },
    {
      title:"İlave Reçeleri",
      url:"/#",
      icon:"list"
    },
    {
      title:"Terbiye Üretim Emirleri",
      url:"/#",
      icon:"list"
    },
    {
      title:"Üretim Hareketleri Girişi",
      url:"/#",
      icon:"list"
    }
  ]
},////Malzeme Yönetimi
    {
      title:"Malzeme Yönetimi",
      url:"/receipt-menu/2",
      icon:"add",
      children:
      [
        {
          title:"Sayım Fişi",
          url:"/receipt-list/100",
          icon:"add"
        },
        {
          title:"Üretime Çıkış Fişi",
          url:"/receipt-list/140",
          icon:"add"
        }
        ,
        {
          title:"Transfer Çıkış Fişi",
          url:"/receipt-list/135",
          icon:"add"
        },{
          title:"Depo Transfer Fişi",
          url:"/receipt-list/17",
          icon:"add"
        }
        
      ]
    },////Satış Dağıtım
    {
      title:"Satış Dağıtım",
      url:"/receipt-menu/3",
      icon:"add",
      children:
      [
        {
          title:"Satış Siparişleri",
          url:"/order-list/",
          icon:"add"
        },
        {
          title:"Toptan Satış İrsaliyesi",
          url:"/receipt-list/120",
          icon:"add"
        },
        {
          title:"Perakende Satış İrsaliyesi",
          url:"/receipt-list/121",
          icon:"add"
        },
        {
          title:"Fason (Alınan) Çıkış İrsaliyesi",
          url:"/receipt-list/134",
          icon:"add"
        },
        {
          title:"Toptan Satış İadesi",
          url:"/receipt-list/3",
          icon:"add"
        },
        {
          title:"Perakende Satış İadesi",
          url:"/receipt-list/2",
          icon:"add"
        },
        {
          title:"Sevkiyat Planlama",
          url:"/#",
          icon:"add"
        },
        {
          title:"Sevkiyat Emirleri",
          url:"/#",
          icon:"add"
        },
      ]
      
    }
  ];
  constructor() { }
}
