import { Injectable } from '@angular/core';
import {Storage} from '@ionic/storage-angular'
import { Account } from '../models/Account/Account';
import { PaymentPlan } from '../models/Order/paymentplan';
import { project } from '../models/Order/project';
import { User } from '../models/user/user';
import { UserResponse } from '../models/user/UserResponse';
import { Warehouse } from '../models/warehouse';


@Injectable({
  providedIn: 'root'
})
export class SettingService {

  private _storage:Storage|null=null;
  isLogged:boolean=false;
  _user:UserResponse;
  paymentPlans:PaymentPlan[];
  warehouses:Warehouse[];
  projects:project[];
  timeout:number;
  constructor(
    private storage:Storage
    
    ) {
    this.init();
   }
   async init()
   {
    const st = await this.storage.create();
    this._storage = st;
    this.isLogged = false;
    
    
   }

   public set(key:string,value:string)
   {
     this._storage.set(key,value);
   }
   public setLastAccount(value:any)
   {
     this._storage.set("LastAccount",value);
   }
   public getLastAccount()
   {
     return this._storage.get("LastAccount");
   }
   public getLocationAlias()
   {
     return this._storage.get("locationAlias");
   }
   setLogin(user:UserResponse)
   {
      this._user = user;
      this.isLogged = true;
   }

   public setDraftList(value:any)
   {
     this._storage.set("DraftList",value);
   }
   public getDraftList()
   {
     this._storage.get("DraftList");
   }
   async get(key:string)
   {
     return new Promise<string>(resolve =>
      {
        this._storage.get(key).then(value=>
          {
            resolve(value);
          })
      });
      
   }
   setPaymentPlans(paymentarray:any)
   {
     this.paymentPlans = paymentarray;
   }
   setProject(paymentarray:any)
   {
     this.projects = paymentarray;
   }
   setWarehouses(value:any)
   {
     this.warehouses = value;
   }
   getWarehouses()
   {
     return this.warehouses;
   }
   setTimeout(timeout:any)
   {
    return this._storage.set("TimeOut",timeout);
   }
   getTimeout()
   {
    return this._storage.get("TimeOut");
   }
   getbarcodeTimeout()
   {
    return this._storage.get("BarcodeTimeout");  
   }
   setbarcodeTimeout(value:number)
   {
    return this._storage.set("BarcodeTimeout",value);  
   }
}
