import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards/auth/auth-guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'loader',
    loadChildren: () => import('./pages/loader/loader.module').then( m => m.LoaderPageModule)
    
    
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'home',
    loadChildren: () => import('./pages/home/home.module').then( m => m.HomePageModule)
    ,canLoad:[AuthGuard]
  },
  
  {
    path: 'recover',
    loadChildren: () => import('./pages/recover/recover.module').then( m => m.RecoverPageModule)
  },
  
  {
    path: 'account-list',
    loadChildren: () => import('./pages/account/account-list/account-list.module').then( m => m.AccountListPageModule)
    ,canLoad:[AuthGuard]
  }
,
  {
    path: 'account',
    loadChildren: () => import('./pages/account/account.module').then( m => m.AccountPageModule)
    ,canLoad:[AuthGuard]
  },
  {
    path: 'account/:id',
    loadChildren: () => import('./pages/account/account.module').then( m => m.AccountPageModule)
    ,canLoad:[AuthGuard]
  },
  {
    path: 'taxoffice',
    loadChildren: () => import('./pages/taxoffice/taxoffice.module').then( m => m.TaxofficePageModule)
    ,canLoad:[AuthGuard]
  },
  {
    path: 'stock-list',
    loadChildren: () => import('./pages/stock/stock-list/stock-list.module').then( m => m.StockListPageModule)
    ,canLoad:[AuthGuard]
  },
  {
    path: 'order-list',
    loadChildren: () => import('./pages/orders/orderlist/orderlist.module').then( m => m.OrderlistPageModule)
    ,canLoad:[AuthGuard]
  },
  {
    path: 'order',
    loadChildren: () => import('./pages/orders/order/order.module').then( m => m.OrderPageModule)
    ,canLoad:[AuthGuard]
  },
  {
    path: 'order/:id',
    loadChildren: () => import('./pages/orders/order/order.module').then( m => m.OrderPageModule)
    ,canLoad:[AuthGuard]
  },
  {
    path: 'receipt-list/:receiptType',
    loadChildren: () => import('./pages/receipt/receipt-list/receipt-list.module').then( m => m.ReceiptListPageModule)
  },
  {
    path: 'receipt-detail',
    loadChildren: () => import('./pages/receipt/receipt-detail/receipt-detail.module').then( m => m.ReceiptDetailPageModule)
  },
  {
    path: 'stock-check',
    loadChildren: () => import('./pages/stock/stock-check/stock-check.module').then( m => m.StockCheckPageModule)
  },
  {
    path: 'workorder-detail',
    loadChildren: () => import('./pages/workorder/workorder-detail/workorder-detail.module').then( m => m.WorkorderDetailPageModule)
  },
  {
    path: 'workorderitem-detail',
    loadChildren: () => import('./pages/workorder/workorderitem-detail/workorderitem-detail.module').then( m => m.WorkorderitemDetailPageModule)
  },
  {
    path: 'workorder-operation',
    loadChildren: () => import('./pages/workorder/workorder-operation/workorder-operation.module').then( m => m.WorkorderOperationPageModule)
  },
  {
    path: 'receipt-menu/:id',
    loadChildren: () => import('./pages/receipt/receipt-menu/receipt-menu.module').then( m => m.ReceiptMenuPageModule)
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules,onSameUrlNavigation:'reload' })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
