import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-set-quantity',
  templateUrl: './set-quantity.component.html',
  styleUrls: ['./set-quantity.component.scss'],
})
export class SetQuantityComponent implements OnInit {

  @Input() value:number;
  inputValue:number;
  canSet:boolean=false;
  constructor(private modalController:ModalController
    ) {
    
   }

   
  ngOnInit() {
    this.inputValue  = this.value;
    this.canSet = this.inputValue>0;
  }
  onInput($event)
  {
    if(this.inputValue>0)
    {
      this.canSet = true;
    }
    else
    {
      this.canSet = false;
    }
  }
  onSetClick()
  {
    this.modalController.dismiss(this.inputValue,"done")
  }
  onCancel()
  {
    this.modalController.dismiss(null,"cancel");
  }

}
